'use client' // Error components must be Client Components

import { useEffectOnce } from '@/hooks/utils/useEffectOnce'
import Image from 'next/image'
import { usePathname, useRouter } from 'next/navigation'

export function generateMetadata({ params, searchParams }, parent) {
  return {
    title: 500,
  }
}
export default function Error({ error, reset }) {
  const router = useRouter()
  const pathname = usePathname()
  useEffectOnce(() => {
    router.push(pathname)
  })
  return (
    <Image
      src={`/img/error/500-XL.jpg`}
      alt={`error 500`}
      title={`error 500`}
      width={1920}
      height={1080}
      style = {{
        width: `100%`,
        height: `75vh`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center`,
        backgroundSize: `cover`,
        display: `flex`,
      }}
    />
  )
}
